var __jsx = React.createElement;
import React from 'react';
import Anchor from '../../atoms/Anchor';
import analyticsConstants from '../../../constants/analyticsConstants';
var headerAnalytics = analyticsConstants.globalHeader;
var NavItem = function NavItem(_ref) {
  var item = _ref.item,
    menuType = _ref.menuType,
    index = _ref.index;
  var styleType;
  if (menuType === 'cta') {
    styleType = index === 0 ? 'primary-anchor' : 'secondary-anchor';
  }
  return __jsx(Anchor, {
    className: "nva-nav-list__item",
    styleType: styleType,
    title: item.label,
    "aria-label": item.ariaLabel,
    to: item.url,
    hyperlinkType: item.hyperlinkType,
    ctaBehavior: item.behavior,
    "data-analytics-type": headerAnalytics.analyticsType.GLOBAL_NAV,
    "data-analytics-value": (item === null || item === void 0 ? void 0 : item.dataAnalyticsValue) || headerAnalytics.analyticsValue.GLOBAL_NAV_CATEGORY_LINK,
    icon: item.icon,
    iconPosition: item.iconPosition
  }, item.label);
};
NavItem.defaultProps = {
  menuType: 'link'
};
export default NavItem;