var __jsx = React.createElement;
import React, { Fragment } from 'react';
import Anchor from '../../../atoms/Anchor';
import List from '../../../molecules/List/List';
import ListItem from '../../../molecules/List/ListItem';
import PhoneIcon from '../../../../static/images/icons/Phone';
import SmsIcon from '../../../../static/images/icons/Comment';
import LocationIcon from '../../../../static/images/icons/Location';
import { getAddressURL, getCompleteAddress, convertPhoneFormat } from '../../../../utils/utilityFunction';
import analyticsConstants from '../../../../constants/analyticsConstants';
import { locationLabels } from '../../../../constants/labels/en';
var headerAnalytics = analyticsConstants.globalHeader;
var contactInformationData = {
  Phone: {
    Icon: PhoneIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(phone) {
      return "".concat(convertPhoneFormat(phone));
    },
    url: function url(phone) {
      return "tel:".concat(convertPhoneFormat(phone));
    }
  },
  Text: {
    Icon: SmsIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_PHONE,
    title: function title(sms) {
      return "".concat(convertPhoneFormat(sms));
    },
    url: function url(sms) {
      return "sms: ".concat(convertPhoneFormat(sms));
    }
  },
  Address: {
    Icon: LocationIcon,
    dataAnalyticsType: headerAnalytics.analyticsType.GLOBAL_NAV,
    dataAnalyticsValue: headerAnalytics.analyticsValue.GLOBAL_NAV_ADDRESS,
    title: function title(address) {
      return address;
    },
    url: function url(addressUrl) {
      return addressUrl;
    }
  }
};
var ContactAnchor = function ContactAnchor(_ref) {
  var className = _ref.className,
    type = _ref.type,
    label = _ref.label,
    contactTitle = _ref.contactTitle,
    contactUrl = _ref.contactUrl,
    icon = _ref.icon;
  if (!contactTitle || !contactUrl) return;
  var _contactInformationDa = contactInformationData[type],
    Icon = _contactInformationDa.Icon,
    dataAnalyticsType = _contactInformationDa.dataAnalyticsType,
    dataAnalyticsValue = _contactInformationDa.dataAnalyticsValue;
  var url = contactInformationData[type].url(contactUrl);
  var title = contactInformationData[type].title(contactTitle);
  var ContactIcon;
  if (icon !== null && icon !== void 0 && icon.embedCode) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__mini-cta-icon",
        "aria-hidden": "true",
        dangerouslySetInnerHTML: {
          __html: icon.embedCode
        }
      });
    };
  } else if (icon !== null && icon !== void 0 && icon.image) {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__mini-cta-icon"
      }, __jsx("img", {
        src: icon.image.url,
        alt: ""
      }));
    };
  } else {
    ContactIcon = function ContactIcon() {
      return __jsx("span", {
        className: "nva-header__mini-cta-icon"
      }, __jsx(Icon, null));
    };
  }
  return __jsx(ListItem, {
    className: className
  }, __jsx(Anchor, {
    title: title,
    ctaBehavior: "Open in a new page",
    className: "heading_five base-color",
    to: url,
    "data-analytics-type": dataAnalyticsType,
    "data-analytics-value": dataAnalyticsValue
  }, __jsx(ContactIcon, null), __jsx("span", {
    className: "nva-header__mini-cta-label"
  }, label)));
};
var ContactItem = function ContactItem(_ref2) {
  var item = _ref2.item,
    contactData = _ref2.contactData;
  var type = item.type,
    label = item.label,
    icon = item.icon;
  if (!type) {
    return __jsx(ListItem, {
      className: "nva-header__mini-cta-item nva-header__mini-cta-item--custom"
    }, __jsx(Anchor, {
      title: label,
      ctaBehavior: item.behavior,
      className: "heading_five base-color",
      to: item.url,
      "data-analytics-type": item.dataAnalyticsType,
      "data-analytics-value": item.dataAnalyticsValue,
      icon: icon,
      iconPosition: item.iconPosition,
      "aria-label": item.ariaLabel
    }, label));
  }
  var _contactData$type = contactData[type],
    className = _contactData$type.className,
    contactTitle = _contactData$type.title,
    contactUrl = _contactData$type.url;
  return __jsx(ContactAnchor, {
    className: className,
    type: type,
    label: label,
    contactTitle: contactTitle,
    contactUrl: contactUrl,
    icon: icon
  });
};
var MiniCTAMenu = function MiniCTAMenu(_ref3) {
  var miniCtaMenuItems = _ref3.miniCtaMenuItems,
    yextContactInformationData = _ref3.yextContactInformationData;
  var contactItems = miniCtaMenuItems ? miniCtaMenuItems : [{
    type: 'Phone',
    label: locationLabels.phone
  }, {
    type: 'Text',
    label: locationLabels.sms
  }, {
    type: 'Address',
    label: locationLabels.addressCamelCase
  }];
  var GMBUrl = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_googleMyBusinessCID) && "https://maps.google.com/maps?cid=".concat(yextContactInformationData.c_googleMyBusinessCID);
  var phone = (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_websitePhone) || (yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.mainPhone);
  var sms = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.c_textPhone;
  var address = yextContactInformationData === null || yextContactInformationData === void 0 ? void 0 : yextContactInformationData.address;
  var addressString = address && getCompleteAddress(address.line1, address.line2, address.city, address.region, address.postalCode);
  var addressStringURL = getAddressURL(addressString);
  var contactData = {
    Phone: {
      title: phone,
      url: phone,
      className: 'nva-header__mini-cta-item nva-header__mini-cta--phone'
    },
    Text: {
      title: sms,
      url: sms,
      className: 'nva-header__mini-cta-item nva-header__mini-cta--sms'
    },
    Address: {
      title: addressString,
      url: GMBUrl || addressStringURL,
      className: 'nva-header__mini-cta-item nva-header__mini-cta--address'
    }
  };
  return __jsx("div", {
    className: "nva-header__mini-cta-menu"
  }, __jsx(List, {
    className: "nva-header__mini-cta-list"
  }, contactItems.map(function (item) {
    return __jsx(Fragment, {
      key: item.sys.id
    }, __jsx(ContactItem, {
      item: item,
      contactData: contactData
    }));
  })));
};
export default MiniCTAMenu;